import React from 'react';
import { SocialLinks } from 'components/common';
import { Thumbnail, SocialColumn, FirstColumn, MiddleColumn } from './styles';
import spaceman from 'assets/images/spaceman.png'
import { Lead, Row, MainBar } from '../../common';
import { FormattedMessage } from 'gatsby-plugin-intl';

export const Footer = () => (
  <Row style={{ alignItems: 'flex-end', display: 'flex' }}>
    <FirstColumn mobile="2" tablet="2" desktop="2" />

    <MainBar>
      <MiddleColumn mobile="0" tablet="0" desktop="4.8">
        <Thumbnail>
          <img src={spaceman} alt="Multiverso ID" />
        </Thumbnail>
      </MiddleColumn>

      <SocialColumn mobile="10" tablet="10" desktop="7.2">
        <SocialLinks reverse={true} />
        <div style={{ paddingBottom: 8 }} />
        <Lead>© Multiverso ID 2019 - {new Date().getFullYear()}. <FormattedMessage id="all_right_reserved" /></Lead>
      </SocialColumn>
    </MainBar>
  </Row>
);

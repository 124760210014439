import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FormattedMessage, IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';
import { Wrapper } from './styles';

type Lang = {
	[key: string]: string;
};

const languageName: Lang = {
	// en: 'English',
	'pt-br': 'Português',
};

type LangContext = {
	languages: [Lang];
	language: string;
};

export const NavbarLinks = ({ desktop }: { desktop?: boolean }) => (
	<Wrapper desktop={desktop}>
		<AnchorLink style={{ padding: '0 25px' }} href="#everything">
			<FormattedMessage id="menu.what" />
		</AnchorLink>
		<AnchorLink style={{ padding: '0 25px' }} href="#discovery">
			<FormattedMessage id="menu.how" />
		</AnchorLink>
		<AnchorLink style={{ padding: '0 25px' }} href="#projects">
			<FormattedMessage id="menu.did" />
		</AnchorLink>
		<AnchorLink style={{ padding: '0 25px' }} href="#team">
			<FormattedMessage id="menu.who" />
		</AnchorLink>
		<AnchorLink style={{ padding: '0 25px' }} href="#contact">
			<FormattedMessage id="menu.want" />
		</AnchorLink>
	</Wrapper>
);

export const NavbarLangs = ({ desktop }: { desktop?: boolean }) => (
	<Wrapper desktop={desktop}>
		{/* <IntlContextConsumer>
			{({ languages, language: currentLocale }: LangContext) =>
				languages.map((language, idx) => (
					<a
						key={idx}
						onClick={() => changeLocale(language)}
						style={{
							color: currentLocale.match(language) === null ? 'white' : 'yellow',
							margin: 10,
							textDecoration: `underline`,
							cursor: `pointer`,
						}}
					>
						{languageName[language]}
					</a>
				))
			}
		</IntlContextConsumer> */}
	</Wrapper>
);

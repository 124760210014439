import styled from 'styled-components';

function getWidthGrid(value: number) {
  if (!value) return;
  let width = value / 12 * 100;
  return `width: ${width}%;`;
}

export const Column = styled.div`
  float: left;
  // padding: .25rem;
  min-height: 1px;
  box-sizing: border-box;
  @media only screen and (max-width: 767px) {
    ${({ mobile }: { mobile: number }) => mobile && getWidthGrid(mobile)}
  }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    ${({ tablet }: { tablet: number }) => tablet && getWidthGrid(tablet)}
  }
  @media only screen and (min-width: 1000px) {
    ${({ desktop }: { desktop: number }) => desktop && getWidthGrid(desktop)}
  }
`;

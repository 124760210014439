module.exports = {
  defaultTitle: 'idmultiverso',
  logo: 'https://portfolio.idmultiverso.com/favicon/favicon-512.png',
  author: 'idmultiverso',
  url: 'https://portfolio.idmultiverso.com',
  legalName: 'idmultiverso',
  defaultDescription: 'idmultiverso site description',
  socialLinks: {
    twitter: 'http://www.twitter.com/multiversoid',
    telegram: 'https://github.com/idmultiverso',
    linkedin: 'https://www.linkedin.com/in/idmultiverso/',
  },
  googleAnalyticsID: 'UA-00000000-0',
  themeColor: '#FED31D',
  backgroundColor: '#030303',
  social: {
    facebook: 'appId',
    twitter: '@multiversoid',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2018',
  recaptcha_key: '########',
};

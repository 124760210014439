import React from 'react';

import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 30px;
  color: #7B7B7B;
  font-weight: bolder;

  ${({ variant }: { variant: string }) =>
    variant &&
    `
    color: ${variant};
  `}
`

export const Lead = styled.p`
  font-size: 14px;
  color: #7B7B7B;
  font-weight: 300;

  ${({ variant }: { variant: string }) =>
    variant &&
    `
    color: ${variant};
  `}
`


export const H4 = styled.h4`
  font-size: 18px;
  color: #7B7B7B;
  font-weight: bolder;

  ${({ variant }: { variant: string }) =>
    variant &&
    `
    color: ${variant};
  `}
`

export const H5 = styled.h5`
  font-size: 16px;
  color: #7B7B7B;
  font-weight: bolder;

  ${({ variant }: { variant: string }) =>
    variant &&
    `
    color: ${variant};
  `}
`

type TypographyTypes = {
  children: string,
}

export const Typography = ({ children }: TypographyTypes) => {
  return (
    <p>{children}</p>
  )
}

import React from 'react';

import styled from 'styled-components';
import { Column } from 'components/common'

const StyledColumn = styled(Column)`
  // border-right: 1px solid #7b7b7b61;
  height: auto;

  display: flex;
  // border-top: 1px solid #7b7b7b61;
  flex-direction: row;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

type MainBarTypes = {
  children: any,
  style?: any
}

export const MainBar = ({ children, style }: MainBarTypes) => {
  return (
    <StyledColumn mobile="10" tablet="10" desktop="10" style={style}>
      {children}
    </StyledColumn>
  )
}

import React from 'react';
import { NavbarLinks, NavbarLangs } from '../NavbarLinks';
import { Wrapper } from './styles';

interface SidebarInterface {
  sidebar: boolean,
  toggle: (sidebar: boolean) => void
}

const Sidebar = ({ sidebar, toggle }: SidebarInterface) => (
  <Wrapper active={sidebar} onClick={toggle}>
    <NavbarLinks />
    <NavbarLangs />
  </Wrapper>
);

export default Sidebar;

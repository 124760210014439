import styled from 'styled-components';

import { Column } from '../../common/Grid/Column';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;


export const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 680px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 0.5rem;

    img {
      margin: 0;
    }

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
`;

export const Details = styled.div`
  @media (max-width: 680px) {
    margin-bottom: 2rem;
  }
`;


export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;

export const SocialColumn = styled(Column)`
  height: 100%;
  display: flex;
  align-items: baseline;
  padding-left: 120px;
  padding-bottom: 32px;
  flex-direction: column;

  @media (max-width: 960px) {
    width: 100%;
    padding: 32px;
    border-left: 1px solid #7b7b7b61;
    border-top: 1px solid #7b7b7b61;

  }
`;

export const FirstColumn = styled(Column)`
  border-right: 1px solid #7b7b7b61;
  height: 100%;

  @media (max-width: 960px) {
    // display: none;
  }
`;

export const MiddleColumn = styled(Column)`
  border-right: 1px solid #7b7b7b61;
  height: 100%;
  display: flex;
  align-items: flex-end;
  background-color: white;

  @media (max-width: 960px) {
    display: none;
  }
`;
import React from 'react';
import { Links, LinksContainer } from './StylesFile';
import social from './social.json';

type TypeSocials = {
  id: number;
  name: string;
  link: string;
  icon: string;
  iconreverse: string;
};

export const SocialLinks = ({ reverse }: { reverse: boolean }) => (
  <Links>
    {social.map(({ id, name, link, icon, iconreverse }: TypeSocials) => (
      <LinksContainer
        reverse={reverse}
        key={id}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`follow me on ${name}`}
      >
        <img width="17" height="17" src={reverse ? iconreverse : icon} alt={name} />
      </LinksContainer>
    ))}
  </Links>
);

import styled from 'styled-components';

export const Links = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 0.5rem;

    img {
      margin: 0;
    }
`;

export const LinksContainer = styled.a`
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: black;
  color: white;

  ${({ reverse }: { reverse: boolean }) =>
    reverse &&
    `
    background: white;
    color: black;
  `}
`;

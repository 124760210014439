import styled from 'styled-components';

export const ContainerFlex = styled.div`
  max-width: ${ (props: { width: number }) => props.width ? props.width : '1400'}px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
`;

import React from 'react';

import styled from 'styled-components';

export const Div = styled.div`
  width: 292px;
  height: 330px;
  margin-bottom: 1.45rem;
  background-position: center;
  ${({ url }: { url: string }) =>
    url &&
    `
      background-image: url(${url});
      width: auto;
      background-size: contain;
      background-repeat: no-repeat;
  `}
  ${({ special }: { special: string }) =>
    special &&
    `
    height: 158px;
    padding: 50px;
    margin-top: 100px;
    margin-bottom: 100px;
  `}
`;

type AvatarTypes = {
  url: string;
  special: boolean;
};

export const Avatar = ({ url, special }: AvatarTypes) => <Div url={url} special={special} />;

import React from 'react';

import styled from 'styled-components';
import { Column } from 'components/common';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Lead } from '../Typography';

const StyledColumn = styled(Column)`
  border-right: 1px solid #7b7b7b61;
  height: auto;
  display: flex;
  border-top: 1px solid #7b7b7b61;

  align-items: flex-start;
  justify-content: center;

  padding-top: 125px;

  @media (max-width: 960px) {
    padding: 32px;
  }
`;

const Title = styled.p`
  color: #ffffff;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;

  ${({ reverse }: { reverse: boolean }) =>
    reverse &&
    `
    color: #000;
`}
`;
const Subtitle = styled.p`
  color: #7b7b7b;
  font-size: 30px;
  font-weight: bolder;
`;

type TextBoxTypes = {
  title: string;
  subtitle: string;
  lead: string;
  reverse?: boolean;
};

export const TextBox = ({ title, subtitle, lead, reverse }: TextBoxTypes) => (
  <StyledColumn mobile="12" tablet="12" desktop="4.8">
    <Column mobile="12" tablet="12" desktop="4.8">
      <Title reverse={reverse}>
        <FormattedMessage id={title} />
      </Title>
      <br />
      {subtitle && (
        <Subtitle>
          <FormattedMessage id={subtitle} />
        </Subtitle>
      )}
      <br />
      {lead && (
        <Lead>
          <FormattedMessage id={lead} />
        </Lead>
      )}
    </Column>
  </StyledColumn>
);

import React from 'react';

import styled from 'styled-components';
import { Column } from 'components/common'
import { FormattedMessage } from 'gatsby-plugin-intl';

const StyledColumn = styled(Column)`
  border-right: 1px solid #7b7b7b61;
  height: auto;
`;

const Text = styled.p`
  color: #3B3B3B;
  font-size: 14px;
  transform: rotate(-90deg);
`

type SidebarTypes = {
  label: string
}

export const Sidebar = ({ label }: SidebarTypes) => {
  return (
    <StyledColumn mobile="2" tablet="2" desktop="2">
      <div style={{ paddingTop: 90 }} />
      <Text><FormattedMessage id={label} /></Text>
    </StyledColumn>
  )
}

import React from 'react';
import { Column } from 'components/common';
import { NavbarLinks, NavbarLangs } from '../NavbarLinks';
import { Wrapper } from './styles';

const Navbar = () => (
  <Wrapper>
    <Column mobile="12" tablet="10" desktop="3" />
    <Column mobile="12" tablet="10" desktop="7">
      <NavbarLinks desktop />
    </Column>
    <Column mobile="12" tablet="10" desktop="2">
      <NavbarLangs desktop />
    </Column>
  </Wrapper>
);
export default Navbar;
